import * as React from 'react'
import styled from '@emotion/styled'

import IndexLayout from '../layouts'
import BikesDiv from "../components/specials/BikesDiv";
import LaundryDiv from "../components/specials/LaundryDiv";
import ServiceDiv from "../components/specials/ServiceDiv";
import HeaderDesktop from "../components/HeaderDesktop";
import EmailVerificationSuccessDiv from "../components/auth/EmailVerificationSuccess";
import BookingSuccessDiv from "../components/booking/BookingSuccess";

const Div = styled.div`
display:flex;
width:80%;
margin-left:auto;
margin-right:auto;
justify-content:space-between;

 @media screen and (max-width: 1295px) {
display:block;
width:80%;


   }
`

  const BookingSuccess = ( {pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <HeaderDesktop></HeaderDesktop>
    <Div>
 <BookingSuccessDiv/>
    </Div>
  </IndexLayout>
)

export default BookingSuccess
